import { cloneComponent } from '@lib/utils';
import { Feed, FeedItem } from '@components/feed';
import { Flex, Box, GridItem, useColorMode, Button } from '@chakra-ui/react';
import { getHomePage } from '@lib/api';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { Grid8 } from '@components/Grid';
import { Splash } from '@components/Splash';
import { useSiteContext } from '@hooks';
import * as React from 'react';
import Layout from '@layout';

export default function IndexPage(props: InferGetStaticPropsType<typeof getStaticProps>) {
	const splash = {
		_key: 'text_9b44e11f517f',
		_type: 'feedText',
		gridColumn: 'col3',
		gridSpan: 'span3',
		text: props.feed.description,
	};

	// const { colorMode, toggleColorMode } = useColorMode();

	return (
		<Layout siteMeta={props.siteMeta} isPreview={props.preview} isHome>
			{/* <Button onClick={toggleColorMode}>Toggle {colorMode === 'light' ? 'Dark' : 'Light'}</Button> */}
			<Grid8 isLarge>
				{/* <Splash slogan={props.feed.description} /> */}
				<Feed
					items={[splash, ...props.feed.items] as FeedItem[]}
					preventContentOverlapWithLogoAndMenu={props.feed.preventContentOverlapWithLogoAndMenu}
				/>
			</Grid8>
		</Layout>
	);
}

export const getStaticProps = async ({ preview }: GetStaticPropsContext) => {
	const page = await getHomePage(preview);

	return {
		props: {
			feed: page.feed,
			preview: !!preview,
			siteMeta: page.siteMeta,
		},
		revalidate: 15,
	};
};
